<template>
  <div class="faq" :class='$mq'>
    <h2 class="center faq" :class='$mq'>Часто задаваемые вопросы</h2>
    <accordion>
      <accordion-panel v-for="(item, index) in items" :key="index">
        <accordion-panel-header v-html="item.header"></accordion-panel-header>
        <accordion-panel-content v-html="item.content"></accordion-panel-content>
      </accordion-panel>
    </accordion>
  </div>
</template>

<script>
export default {
  name: "KFaq",
  props: {
    items: {
      type: Array,
      default: [
        { header: "test header", content:"<a href='https://ya.ru'>test</a>" }
      ]
    }
  }
}
</script>

<style scoped>
  .faq {
    font-size: 0.9em;
  }

  h2.faq {
    font-size: 3em;
    margin-bottom: 0.5em;
  }
  h2.faq.mobile {
    font-size: 2em;
  }

  h2.center {
    text-align: center;
    margin-bottom: 1em;
  }

  :deep(.accordion__panel button) {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
  }

  :deep(.accordion__panel .accordion__panel-header-toggle) {
    width: 100%;
    display: block;
    text-align: left;
    border: 0px solid transparent;
    border-radius: 0.5em;
    background: #00000022;
    color: white;
    padding: 0.8em 1.5em;
    margin-bottom: 0.5em;
  }

  :deep(.accordion__panel-content) {
    font-size: 0.9em;
    margin: 0 2em 1.5em 2em;
    animation-name: open;
    animation-duration: 0.6s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-delay: 0s;
  }

  @keyframes open {
    0% {
      display: block;
      opacity: 0;
      visibility: hidden;
    }
    100% {
      display: block;
      opacity: 1;
      visibility: visible;
    }
  }
</style>